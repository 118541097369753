import { html } from 'lit';
import { BaseElement } from '../base.js';
import { request } from '../../lib/request.js';
import { ORDER_OPERATIONS, ORDER_ACTIONS } from '../../lib/constants.js';
import spinner from '../../html/spinner.js';
import './modal-notification-email.js';
import './modal-notifications.js';
import './modal-sftp-server.js';
import './modal-sftp-export.js';
import './modal-sftp-import.js';
import './modal-sftp-import-pricing.js';
import './modal-webhook-config.js';

export class ConnectAccountIntegrations extends BaseElement {
  static properties = {
    item: { state: true },
    pending: { state: true },
    updating: { state: true },
    sftp: { state: true },
    webhook: { state: true },
    tree: { state: true },
    loading_tree: { state: true },
    loading_file: { state: true }
  };

  constructor() {
    super();
    this.item = null;
    this.pending = false;
    this.updating = false;
    this.sftp = {};
    this.webhook = {};
    this.tree = null;
    this.loading_tree = false;
    this.loading_file = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadConfig();
  }

  async loadConfig() {
    this.pending = true;
    try {
      this.sftp = await request(
        'GET',
        `/connect-accounts/${this.item.account}/sftp`
      );
      this.webhook = await request(
        'GET',
        `/connect-accounts/${this.item.account}/webhook`
      );
    } finally {
      this.pending = false;
    }
  }

  onChangeSftpServer() {
    this.emit(
      'modal:open',
      html`
        <modal-sftp-server
          .account="${this.item.account}"
          .sftp=${this.sftp}
          @updated=${(e) => {
            this.sftp = e.detail;
            this.tree = null;
          }}
        ></modal-sftp-server>
      `
    );
  }

  onChangeWebhookConfig() {
    this.emit(
      'modal:open',
      html`
        <modal-webhook-config
          .account="${this.item.account}"
          .webhook=${this.webhook}
          @updated=${(e) => {
            this.webhook = e.detail;
          }}
        ></modal-webhook-config>
      `
    );
  }

  onChangeOrderExport() {
    this.emit(
      'modal:open',
      html`
        <modal-sftp-export
          .account="${this.item.account}"
          .sftp=${this.sftp}
          @updated=${(e) => {
            this.sftp.export_config = e.detail;
            this.requestUpdate();
          }}
        ></modal-sftp-export>
      `
    );
  }

  onChangeItemImport() {
    this.emit(
      'modal:open',
      html`
        <modal-sftp-import
          .account="${this.item.account}"
          .sftp=${this.sftp}
          @updated=${(e) => {
            this.sftp.import_config = e.detail;
            this.requestUpdate();
          }}
        ></modal-sftp-import>
      `
    );
  }

  onChangeItemImportPricing() {
    this.emit(
      'modal:open',
      html`
        <modal-sftp-import-pricing
          .account="${this.item.account}"
          .sftp=${this.sftp}
          @updated=${(e) => {
            this.sftp.import_config.pricing = e.detail;
            this.requestUpdate();
          }}
        ></modal-sftp-import-pricing>
      `
    );
  }

  async onShowTree() {
    this.loading_tree = true;
    this.tree = null;
    try {
      const { tree } = await request(
        'GET',
        `/connect-accounts/${this.item.account}/sftp/tree`
      );
      this.tree = tree;
    } finally {
      this.loading_tree = false;
    }
  }

  render() {
    if (this.pending) {
      return spinner();
    }
    return html`
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4">
        ${this.renderEmailCard()} ${this.renderNotificationsCard()}
        ${this.renderSftpCard()}${this.renderWebhookCard()}
        ${this.renderItemExportCard()} ${this.renderItemImportCard()}
        ${this.renderItemImportPricingCard()}
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4">
        ${this.renderTree()}
      </div>
    `;
  }

  async onDownloadFile(path) {
    this.loading_file = path;
    try {
      const { url } = await request(
        'POST',
        `/connect-accounts/${this.item.account}/sftp/download`,
        { path }
      );
      window.location.href = url;
    } finally {
      this.loading_file = null;
    }
  }

  renderEmailCard() {
    const { emails_default } = this.item.notifications;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Notification Email(s)</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${this.onChangeEmail}
            >
              Change
            </button>
          </div>
          <div class="card-body">
            ${emails_default.map(
              (email) => html`
                <a class="text-decoration-none" href="mailto:${email}">
                  ${email}
                </a>
              `
            )}
          </div>
        </div>
      </div>
    `;
  }

  onChangeEmail() {
    const { emails_default } = this.item.notifications;
    this.emit(
      'modal:open',
      html`
        <modal-notification-email
          account="${this.item.account}"
          .emails=${emails_default}
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-notification-email>
      `
    );
  }

  renderNotificationsCard() {
    const { notifications } = this.item;

    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Notification methods</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onUpdateNotifications}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${ORDER_OPERATIONS.map(
              (operation) => html`
                <div class="mb-3">
                  <h6 class="text-capitalize">${operation}</h6>
                  ${ORDER_ACTIONS.map(
                    (action) => html`
                      <div class="ps-2 my-2 hstack gap-2">
                        <span class="me-auto">${action}</span>
                        ${notifications[operation][action].methods.map(
                          (method) => html`
                            <div class="badge text-bg-secondary">${method}</div>
                          `
                        )}
                      </div>
                    `
                  )}
                </div>
              `
            )}
          </div>
        </div>
      </div>
    `;
  }

  onUpdateNotifications() {
    const { notifications } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-notifications
          account="${this.item.account}"
          .notifications=${notifications}
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-notifications>
      `
    );
  }

  renderSftpCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">(S)FTP Server</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeSftpServer}
            >
              Update
            </button>
          </div>
          <div class="card-body hstack">
            ${this.sftp.hostname
              ? html`
                  <a
                    href="javascript:;"
                    class="text-decoration-none"
                    @click=${this.onShowTree}
                  >
                    ${this.sftp.protocol || 'sftp:'}//${this.sftp
                      .hostname}:${this.sftp.port || 22}
                  </a>
                `
              : html`<span class="text-muted">Not configured</span>`}
          </div>
        </div>
      </div>
    `;
  }

  renderWebhookCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Webhook config</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeWebhookConfig}
            >
              Update
            </button>
          </div>
          <div class="card-body hstack">
            ${this.webhook.hostname
              ? html`
                  <a
                    href="javascript:;"
                    class="text-decoration-none"
                    @click=${this.onShowTree}
                  >
                    ${this.webhook.protocol || 'https:'}//${this.webhook
                      .hostname}/${this.webhook.path}:${this.webhook.port || 22}
                  </a>
                `
              : html`<span class="text-muted">Not configured</span>`}
          </div>
        </div>
      </div>
    `;
  }

  renderItemExportCard() {
    if (!this.sftp.hostname) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Order Export</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeOrderExport}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${this.sftp.export_config
              ? html`<code>${this.sftp.export_config.remote_file}</code>`
              : html`<span class="text-muted">Not configured</span>`}
          </div>
        </div>
      </div>
    `;
  }

  renderItemImportCard() {
    if (!this.sftp.hostname) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Item Import</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeItemImport}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${this.sftp.import_config
              ? html`<code>${this.sftp.import_config.remote_file}</code>`
              : html`<span class="text-muted">Not configured</span>`}
          </div>
        </div>
      </div>
    `;
  }

  renderItemImportPricingCard() {
    if (!this.sftp.import_config) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Pricing Import</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeItemImportPricing}
            >
              Update
            </button>
          </div>
          <div class="card-body hstack gap-2">
            ${this.sftp.import_config.pricing
              ? this.sftp.import_config.pricing.remote_files.map(
                  (filename, i) =>
                    html`<span>${i ? '+ ' : ''}<code>${filename}</code></span>`
                )
              : html`<span class="text-muted">Not configured</span>`}
          </div>
        </div>
      </div>
    `;
  }

  renderTree() {
    if (!this.tree && !this.loading_tree) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Files on server</h5>
          </div>
          <div class="card-body">
            <div class="vstack gap-3">
              ${this.tree
                ? this.tree.map((node) => this.renderTreeNode(node))
                : this.loading_tree
                  ? spinner()
                  : null}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  renderTreeNode(node, path = '') {
    if (node.type === 'file') {
      const full_path = `${path}/${node.name}`;
      return html`
        <div class="hstack gap-2">
          <i class="bi bi-file-earmark"></i>
          <span>${node.name}</span>
          <a
            href="javascript:;"
            @click=${() => this.onDownloadFile(`${full_path}`)}
          >
            ${this.loading_file === full_path
              ? html`<div class="spinner-border spinner-border-sm"></div>`
              : html`<i class="bi bi-download"></i>`}
          </a>
        </div>
      `;
    }
    return html`
      <div>
        <div class="hstack gap-2">
          <i class="bi bi-folder"></i>
          <span class="me-auto">${node.name}</span>
        </div>
        <div class="vstack gap-3 px-4 pt-2">
          ${node.children.length
            ? node.children.map((child) =>
                this.renderTreeNode(child, `${path}/${node.name}`)
              )
            : html`<div><small class="text-muted">Empty</small></div>`}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'connect-account-integrations',
  ConnectAccountIntegrations
);
