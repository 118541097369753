import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';
import { deepCopy } from '../../lib/deep-copy.js';
import renderOptions from '../../html/options.js';
import { trimEmpty } from '../shared/key-value-editor.js';

const protocols = ['http:', 'https:'];
const methods = ['POST', 'PUT', 'PATCH'];
const email_options = ['', 'ALWAYS', 'NEVER', 'ON_FAILURE'];

class ModalWebhookConfig extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    webhook: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Webhook Configuration';
    this.account = '';
    this.webhook = {};
    this.saving = false;
    this.show_section = {
      auth: false,
      headers: false,
      config: false
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.webhook = deepCopy(this.webhook);

    if (!this.webhook.headers) {
      this.webhook.headers = {};
    }

    if (this.webhook.authorization) {
      this.webhook.username = this.webhook.authorization.username;
      this.webhook.password = this.webhook.authorization.password;
    }

    if (this.webhook.config) {
      this.webhook.send_email = this.webhook.config.send_email;
    }

    this.show_section.auth = Boolean(this.webhook.username);
    this.show_section.headers = Boolean(
      Object.keys(this.webhook.headers).length
    );
    this.show_section.config = Boolean(this.webhook.send_email);
  }

  getDefaultPort() {
    return this.webhook.protocol === 'http:' ? 80 : 443;
  }

  async onSave() {
    this.saving = true;

    const json = {
      protocol: this.webhook.protocol || 'https:',
      method: this.webhook.method || 'POST',
      hostname: this.webhook.hostname,
      path: this.webhook.path,
      port: this.webhook.port || this.getDefaultPort()
    };

    if (this.webhook.username) {
      json.authorization = {
        username: this.webhook.username,
        password: this.webhook.password
      };
    }

    if (this.webhook.send_email) {
      json.send_email = this.webhook.send_email;
    }

    const trimmed_headers = trimEmpty(this.webhook.headers);
    if (Object.keys(trimmed_headers).length) {
      json.headers = trimmed_headers;
    }

    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/webhook`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  async onRemove() {
    this.saving = true;

    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/webhook`,
      {}
    );
    this.emit('updated', response);
    this.close();
  }

  onUpdateField(e) {
    this.webhook[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  toggleSection(section) {
    this.show_section[section] = !this.show_section[section];

    this.requestUpdate();
  }

  renderBody() {
    const {
      protocol,
      method,
      hostname,
      path,
      port,
      username,
      password,
      headers,
      send_email
    } = this.webhook;

    return html`
      <div class="row">
        <label class="col-4 col-form-label">Protocol</label>
        <div class="col-3">
          <select
            class="form-select"
            name="protocol"
            @change=${this.onUpdateField}
          >
            ${renderOptions(protocols, protocol || 'https:')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Method</label>
        <div class="col-8">
          <select
            class="form-select"
            name="method"
            @change=${this.onUpdateField}
          >
            ${renderOptions(methods, method || 'POST')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Hostname</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="hostname"
            .value=${hostname || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Path</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="path"
            .value=${path || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Port</label>
        <div class="col-3">
          <input
            type="text"
            class="form-control"
            name="port"
            placeholder="${this.getDefaultPort()}"
            .value=${port || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      ${this.renderSection(
        'auth',
        'Authorization',
        html`
          <div class="row mt-3">
            <label class="col-4 col-form-label">Username</label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                placeholder="yourbarmate"
                name="username"
                .value=${username || ''}
                @input=${this.onUpdateField}
              />
            </div>
          </div>
          <div class="row mt-1">
            <label class="col-4 col-form-label">Password</label>
            <div class="col-8">
              <input
                type="password"
                class="form-control"
                name="password"
                .value="${password || ''}"
                @input=${this.onUpdateField}
              />
            </div>
          </div>
        `
      )}
      ${this.renderSection(
        'headers',
        'Headers',
        html`
          <div class="row mt-3">
            <div class="col-12">
              <shared-key-value-editor
                .values=${headers}
                @change=${() => this.requestUpdate()}
              >
              </shared-key-value-editor>
            </div>
          </div>
        `
      )}
      ${this.renderSection(
        'config',
        'Additional configuration',
        html`
          <div class="row mt-3">
            <label class="col-4 col-form-label">Send email</label>
            <div class="col-3">
              <select
                class="form-select"
                name="send_email"
                @change=${this.onUpdateField}
              >
                ${renderOptions(email_options, send_email)}
              </select>
            </div>
          </div>
        `
      )}
    `;
  }

  renderSection(key, title, section) {
    const { show_section } = this;

    return html`
      <div
        class="row mt-3 border-bottom"
        @click=${() => this.toggleSection(key)}
        role="button"
      >
        <div class="col-12">
          <small>
            <i
              class="bi bi-chevron-${show_section[key] ? 'down' : 'right'} mb-1"
            >
            </i>
          </small>
          <strong class="col-12 col-form-label"> ${title} </strong>
        </div>
      </div>
      ${show_section[key] ? section : null}
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-outline-danger me-auto"
        @click=${this.onRemove}
        ?disabled=${this.saving}
      >
        Remove
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Save
      </button>
    `;
  }
}

customElements.define('modal-webhook-config', ModalWebhookConfig);
