import { html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { BaseElement } from '../base.js';

class SharedKeyValueEditor extends BaseElement {
  static properties = {
    values: { state: true },
    config: { state: true }
  };

  constructor() {
    super();
    this.values = {};
    this.config = {};
  }

  render() {
    const render_keys = Object.keys(this.values).map((key, i) => ({
      id: `id-${i}:${key}`,
      key
    }));

    return html`
      ${repeat(
        render_keys,
        ({ id }) => id,
        ({ key, id }) => html`
          <div class="input-group mb-2" id="${`key-${id}`}">
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="key"
              value=${key}
              @change=${(event) => {
                const value = this.values[key];
                delete this.values[key];
                this.values[event.target.value] = value;
                this.emit('change');
                this.requestUpdate();
              }}
            />
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="value"
              value=${this.values[key]}
              @change=${(event) => {
                this.values[key] = event.target.value;
                this.emit('change');
                this.requestUpdate();
              }}
            />
            <button
              class="btn btn-sm btn-secondary"
              @click=${() => this.delete(key)}
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        `
      )}
      <button
        class="btn btn-sm btn-primary"
        @click=${() => {
          this.values[''] = '';
          this.emit('change');
          this.requestUpdate();
        }}
      >
        <i class="bi bi-plus"></i>Add
      </button>
    `;
  }

  delete(key) {
    delete this.values[key];
    this.emit('change');
    this.requestUpdate();
  }
}

customElements.define('shared-key-value-editor', SharedKeyValueEditor);

export function trimEmpty(obj) {
  const not_empty = {};

  for (const key in obj) {
    if (key && obj[key]) {
      not_empty[key] = obj[key];
    }
  }

  return not_empty;
}
